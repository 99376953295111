* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
/* Nav */
.list {
  width: 100%;
  height: 80px;
  background-color: #242224;
  border-radius: 0 0 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
}

.liNav {
  width: 40%;
    transition: .5s;
}
ul {
  display: flex;
  justify-content: space-around;


}

ul li {
  list-style: none;
  border-bottom: 2px solid;
  color: white;
}

/* corse   */

.container {
  display: grid;
  grid-template-columns: auto auto auto;
  /* grid-template-rows: 1fr 1fr 1fr; */
  align-items: center;
  justify-content: space-around;
  width: 100%;
  background-color: #797772;
}
 .course{
  width: 100% ;
  height: 400px;
}
.course p{
  width: 50%;
  justify-self: center;
}

img {
  width: 60%;
  margin: 10%;
}
.foteer {
  background-color: rgb(172, 200, 224);
  padding: 5px;
}
p {
  text-align: center;
  border-bottom: 2px solid;
}

.link{
  background-color: #242224;
  text-align: center;

}
a{
  color:white;
  font-size: 24px;
}

.icon h3{
  color: white;
}